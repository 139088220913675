/* eslint-disable no-undef */
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import Cookies from "js-cookie";

import { getTenantFromHost } from "common/helper";
import { TENANTS_LIST } from "constants/index";

const FINX_COOKIE = require("common/constants");

const isSentryEnabled = process.env.SENTRY_ENABLED === "true" || process.env.SENTRY_ENABLED === true;
const isTenantSachwertinvest = getTenantFromHost(window.location.host) === TENANTS_LIST.SACHWERTINVEST;

const APP_SENTRY_ENABLED_TENANT = {
    [TENANTS_LIST.FINEXITY]: true,
    [TENANTS_LIST.GOLDENCIRCLE]: true,
};

const checkSentryEnabledNonProdEnvs = () => {
    return !isTenantSachwertinvest && isSentryEnabled && process.env.SENTRY_ENVIRONMENT !== "prod";
};

function initiateSentry() {
    const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

    Sentry.init({
        dsn: SENTRY_DSN,
        tracesSampleRate: 1.0,
        enabled: isSentryEnabled,
        release: process.env.SENTRY_RELEASE,
        environment: process.env.SENTRY_ENVIRONMENT,
    });
}

const handleConsent = (isConsentGiven) => {
    Cookies.set(FINX_COOKIE.CONSENTMANAGER_CONSENT, isConsentGiven);
    localStorage.setItem(FINX_COOKIE.CONSENTMANAGER_CONSENT, isConsentGiven);
    if (isConsentGiven == "true") {
        initiateSentry();
    }
};

// Check if user has already accepted the consent
const isCookieConsentAccepted = !!(
    (Cookies.get(FINX_COOKIE.COOKIEFIRST_CONSENT) && localStorage.getItem(FINX_COOKIE.COOKIEFIRST_CONSENT)) ||
    (Cookies.get(FINX_COOKIE.CONSENTMANAGER_CONSENT) == "true" &&
        localStorage.getItem(FINX_COOKIE.CONSENTMANAGER_CONSENT) == "true")
);

const checkSentryEnabledForNativeApp = () => {
    const tenant = getTenantFromHost(window.location.host);
    const isSentryEnabledForTenantApp = APP_SENTRY_ENABLED_TENANT[tenant];
    const isAppPlatform = Cookies.get(FINX_COOKIE.PLATFORM_SOURCE) === "app";
    return isAppPlatform && isSentryEnabledForTenantApp;
};

if (isCookieConsentAccepted || checkSentryEnabledForNativeApp() || checkSentryEnabledNonProdEnvs()) {
    initiateSentry();
} else {
    //Listening the event when user accept the consent.
    window.addEventListener("cf_consent", initiateSentry);

    // Additional configuration for to listen consent on the SachwertInvest tenant
    if (isTenantSachwertinvest) {
        let retries = 0;
        const maxRetries = 5;

        const intervalTimer = setInterval(() => {
            retries++;

            if (window?.cmp_cdn === "cdn.consentmanager.net" && typeof __cmp === "function") {
                clearInterval(intervalTimer);

                __cmp("addEventListener", ["consentrejected", () => handleConsent("false"), false], null);
                __cmp("addEventListener", ["consentapproved", () => handleConsent("true"), false], null);
                __cmp("addEventListener", ["consentcustom", () => handleConsent("true"), false], null);

                if (
                    Cookies.get(FINX_COOKIE.CONSENTMANAGER_CONSENT) != "true" &&
                    localStorage.getItem(FINX_COOKIE.CONSENTMANAGER_CONSENT) != "true" &&
                    Cookies.get(FINX_COOKIE.CONSENTMANAGER_CONSENT) != "false" &&
                    localStorage.getItem(FINX_COOKIE.CONSENTMANAGER_CONSENT) != "false"
                ) {
                    __cmp("showScreen");
                }
            }

            // Stop the interval after maxRetries attempts to prevent an infinite loop
            if (retries >= maxRetries) {
                clearInterval(intervalTimer);
            }
        }, 2000);
    }
}
