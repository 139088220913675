import React from "react";
import { connect } from "react-redux";
import { updateMessagesSuccess } from "store/user/message/actions";
import Message from "assets/images/svg/message.svg";
import moment from "moment";
import { convertDBDateToDefaultFormat, getPreviousDay } from "utils";

const MessageTile = ({ message, setMessage, onMessageClick }) => {
    const isRead = message?.readDate;

    const formateDate = (date) => {
        const formattedDate = date.split("_")[0];
        const previousDate = moment(new Date(getPreviousDay())).format("YYYY-MM-DD");
        const currentDate = moment(new Date()).format("YYYY-MM-DD");

        return formattedDate == currentDate
            ? moment(convertDBDateToDefaultFormat(date)).format("hh:mm")
            : formattedDate == previousDate
              ? "Yesterday"
              : moment(convertDBDateToDefaultFormat(date)).format("DD/MM/YYYY");
    };
    return (
        <>
            <div
                className={`${
                    isRead ? "read-message-tile" : "unread-message-tile"
                } flex align-center px-4 w-100 py-5 position-relative cursor-pointer menu-tab`}
                onClick={() => {
                    setMessage(message);
                    onMessageClick();
                }}
            >
                <div className="flex">
                    <div className="text-16 pr-3">
                        <Message />
                    </div>
                    <div className="flex column">
                        <div className="text-16 message-preview">{message.preview}</div>
                        <div className="text-12 mt-2 another-grey-color message-created-date">
                            {formateDate(message.createdDate)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateMessagesSuccess: (notifications) => dispatch(updateMessagesSuccess(notifications)),
});

export default connect(null, mapDispatchToProps)(MessageTile);
