import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectTenant } from "store/app/selectors";
import SideModal from "components/SideModal";
import WrappedMangopayWallet from "components/EuroWalletSideModal/WrappedMangopayWallet";
import WrappedMobileModal from "components/MobileModal/WrappedMobileModal";
import WrappedKycModal from "components/KycModal/WrappedKycModal";
import WrappedAdequacyModal from "components/AdequacyModal/WrappedAdequacyModal";
import InboxModal from "components/Inbox";
import WrappedSettingsModal from "components/Settings/WrappedSettingsModal";
import ContactModal from "components/ContactModal";
// import ReferralModal from "components/Referral";
import SettingSvg from "assets/images/svg/settings.svg";
// import InviteFriendSvg from "assets/images/svg/invite-frient.svg";
import EmailSvg from "assets/images/svg/e-mail.svg";
import ContactUsSvg from "assets/images/svg/contact-us.svg";
import LogoutSvg from "assets/images/svg/logout-2.svg";
import {
    selectIsLoggedIn,
    selectUserDashboardDetails,
    selectEmail,
    selectUserNameWithTypeTitle,
} from "store/user/account/selectors";
import { getUnreadCount } from "store/user/message/selectors";
import { exitInheritUser } from "store/user/account/actions";

import { ACCOUNT_HASH_VARIABLES } from "constants/index";
import { TILE_STATUS, MOBILE_STATUS, KYC_STATUS_LOCAL } from "constants/status";
import Auth from "utils/auth0";
import { logoutTitle } from "i18n/messages/accountMenu";
import { addOverlay, removeOverlay, sideModalScrollHidden } from "utils";
import { useFormattedMessage, useUtils } from "hooks";
import { selectCurrenKYCStatus } from "store/user/kyc/selectors";

const FINX_COOKIE = require("common/constants");

const defineText = {
    mainHeadingFirst: "account_heading",
    accountNotificationTabUnread: "account_notification_tab_unread",
    accountNotificationTabRead: "no_new_messages",
    manageYourAccount: "dashboard_nav_submenu_setting",
    inviteFriends: "meta_title_account_referral_page",
    contact: "contact",
    myData: "meta_title_account_profile_page",
    securityPin: "update_pin",
    information: "information",
    tabInbox: "inbox",
    yourDedicatedAdvisor: "your_dedicated_advisor",
    messages: "messages",
    hello: "hello",
};

const isInheritUser = !!Cookies.get(FINX_COOKIE.INHERIT_USER_TOKEN);

export const Row = ({ onClick, svg, title, svgClass }) => {
    return (
        <div className="flex align-center py-4 w-100 px-5 cursor-pointer menu-tab" onClick={onClick}>
            <div className="fw-500 text-16 flex align-center">
                <span className={`pr-3 flex ${svgClass}`}>{svg}</span>
                <span>{title}</span>
            </div>
        </div>
    );
};

const WrappedAccountModal = ({
    open,
    onCloseButtonClick,
    isLoggedIn,
    openSmModal,
    userDashboardData,
    setContactModalFlag,
    contactModalFlag,
    unreadCount,
    setIsHamburgerMenuCollapsed,
    userEmail,
    currentTenant,
    currentKYCStatus,
    userNameWithTypeTitle,
}) => {
    const router = useRouter();
    const { formatMessage } = useFormattedMessage();
    const { isTenantFinexity } = useUtils();

    const [isMainAccountModalOpen, setMainAccountModalOpen] = useState(false);
    const [isInboxModalOpen, setInboxModalOpen] = useState(false);
    const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
    const [adequacyModalFlag, setAdequacyModalFlag] = useState(false);
    const [kycModalFlag, setKycModalFlag] = useState(false);
    const [mobilePhoneModalFlag, setMobilePhoneModalFlag] = useState(false);
    // const [isReferralModalOpen, setReferralModalOpen] = useState(false);
    const [isMobileView, setMobileView] = useState(false);

    useEffect(() => {
        jQuery(".side-modal").animate({ scrollTop: 0 });
        window.innerWidth <= 991 && setMobileView(true);
    }, [open]);

    useEffect(() => {
        if (userEmail) {
            const hash = window?.location?.hash?.substring(1);
            if (Object.values(ACCOUNT_HASH_VARIABLES).includes(hash)) {
                setMainAccountModalOpen(true);
            } else if (hash === MOBILE_STATUS.HASH_VARIABLE) {
                setMainAccountModalOpen(true);
                setSettingsModalOpen(true);
            }

            if (hash === ACCOUNT_HASH_VARIABLES.ACCOUNT_MESSAGE) {
                setInboxModalOpen(true);
            } else if (hash === ACCOUNT_HASH_VARIABLES.ACCOUNT_KYC) {
                setKycModalFlag(true);
            } else if (hash === ACCOUNT_HASH_VARIABLES.ACCOUNT_SETTING) {
                setSettingsModalOpen(true);
            }
            // else if (hash === ACCOUNT_HASH_VARIABLES.ACCOUNT_REFERRAL) {
            //     setReferralModalOpen(true);
            // }
            else if (hash === ACCOUNT_HASH_VARIABLES.ACCOUNT_CONTACT) {
                setContactModalFlag(true);
            }
        }
    }, [userEmail]);

    useEffect(() => {
        if (isMainAccountModalOpen) {
            addOverlay();
            sideModalScrollHidden(true);
        } else {
            removeOverlay();
            sideModalScrollHidden(false);
        }
    }, [isMainAccountModalOpen]);

    useEffect(() => {
        const onHashChanged = () => {
            const hash = window.location.hash.substring(1);
            if (hash === ACCOUNT_HASH_VARIABLES.ACCOUNT_MAIN) {
                setMainAccountModalOpen(true);
            } else if (hash === ACCOUNT_HASH_VARIABLES.ACCOUNT_KYC) {
                setKycModalFlag(true);
            } else if (hash === ACCOUNT_HASH_VARIABLES.ACCOUNT_MESSAGE) {
                setInboxModalOpen(true);
            } else if (hash === ACCOUNT_HASH_VARIABLES.ACCOUNT_SETTING) {
                setSettingsModalOpen(true);
            }
            // else if (hash === ACCOUNT_HASH_VARIABLES.ACCOUNT_REFERRAL) {
            //     setReferralModalOpen(true);
            // }
            else if (hash === ACCOUNT_HASH_VARIABLES.ACCOUNT_CONTACT) {
                setContactModalFlag(true);
            }
        };

        window.addEventListener("hashchange", onHashChanged);

        return () => {
            window.removeEventListener("hashchange", onHashChanged);
        };
    }, []);

    const StatusMobilePhone = userDashboardData?.StatusMobilePhone;
    const mobilePhoneCompleted = !!(StatusMobilePhone === TILE_STATUS.HIDE || StatusMobilePhone === TILE_STATUS.SUCCESS);
    const StatusAdequacy = userDashboardData?.StatusAdequacy;
    const adequacyCompleted = !!(StatusAdequacy == TILE_STATUS.SUCCESS || StatusAdequacy == TILE_STATUS.HIDE);

    const addHash = (hash) => (window.location.hash = hash);

    const removeHash = () => {
        router.pathname === "/marketplace/[id]" && router?.query?.id
            ? router.push(`/marketplace/${router?.query?.id}`)
            : router.replace(window.location.href.split("#")[0]);
    };

    const logoutFunction = () => {
        if (isLoggedIn) {
            if (isInheritUser) {
                exitInheritUser();
            } else {
                Auth.customLogout();
            }
        }
    };

    const titlesData = [
        {
            onClick: () => addHash(ACCOUNT_HASH_VARIABLES.ACCOUNT_MESSAGE),
            title: formatMessage(defineText.messages),
            svg: <EmailSvg />,
            svgClass: `${unreadCount ? "notification-dot" : ""} inbox-svg`,
            isRender: true,
        },
        {
            onClick: () => addHash(ACCOUNT_HASH_VARIABLES.ACCOUNT_SETTING),
            title: formatMessage(defineText.manageYourAccount),
            svg: <SettingSvg />,
            svgClass: "setting-svg",
            isRender: true,
        },
        // commented for IT-7497
        // {
        //     onClick: () => addHash(ACCOUNT_HASH_VARIABLES.ACCOUNT_REFERRAL),
        //     title: formatMessage(defineText.inviteFriends),
        //     svg: <InviteFriendSvg />,
        //     svgClass: "invite-friend-svg",
        //     isRender: isTenantFinexity,
        // },
        {
            onClick: () => addHash(ACCOUNT_HASH_VARIABLES.ACCOUNT_CONTACT),
            title: formatMessage(defineText.yourDedicatedAdvisor),
            svg: <ContactUsSvg />,
            svgClass: "contact-us-svg",
            isRender: isTenantFinexity,
        },
        {
            onClick: logoutFunction,
            title: isInheritUser ? "Exit" : formatMessage(logoutTitle),
            svg: <LogoutSvg />,
            svgClass: "logout-svg",
            isRender: true,
        },
    ];

    const onAccountModalClose = () => {
        if (onCloseButtonClick) {
            onCloseButtonClick();
            setMainAccountModalOpen(false);
            removeHash();
        }
    };

    return (
        <SideModal
            className="account-modal"
            heading={formatMessage(defineText.mainHeadingFirst)}
            open={open || isMainAccountModalOpen}
            isBackArrowOnTop={isMobileView}
            isCloseArrowOnRight
            onBackButtonClick={onAccountModalClose}
            onCloseButtonClick={() => {
                isMobileView && setIsHamburgerMenuCollapsed(true);
                onAccountModalClose();
            }}
        >
            {/* Commented code for now IT-7497 */}
            {/* <ReferralModal
                open={isReferralModalOpen}
                onCloseButtonClick={() => {
                    setReferralModalOpen(false);
                    onAccountModalClose();
                }}
                onBackButtonClick={() => {
                    setReferralModalOpen(false);
                    addHash(ACCOUNT_HASH_VARIABLES.ACCOUNT_MAIN);
                }}
            /> */}

            <ContactModal
                open={contactModalFlag}
                onCloseButtonClick={() => {
                    setContactModalFlag(false);
                    onAccountModalClose();
                }}
                onBackButtonClick={() => {
                    setContactModalFlag(false);
                    addHash(ACCOUNT_HASH_VARIABLES.ACCOUNT_MAIN);
                }}
                isBackArrowOnTop
            />

            <WrappedSettingsModal
                open={isSettingsModalOpen}
                onCloseButtonClick={() => {
                    setSettingsModalOpen(false);
                    onAccountModalClose();
                }}
                setAdequacyModalFlag={setAdequacyModalFlag}
                adequacyCompleted={adequacyCompleted}
                onBackButtonClick={() => {
                    setSettingsModalOpen(false);
                    addHash(ACCOUNT_HASH_VARIABLES.ACCOUNT_MAIN);
                }}
                setKycModalFlag={setKycModalFlag}
                currentTenant={currentTenant}
            />

            <InboxModal
                open={isInboxModalOpen}
                onCloseButtonClick={() => {
                    setInboxModalOpen(false);
                    onAccountModalClose();
                }}
                onBackButtonClick={() => {
                    setInboxModalOpen(false);
                    addHash(ACCOUNT_HASH_VARIABLES.ACCOUNT_MAIN);
                }}
                setAdequacyModalFlag={setAdequacyModalFlag}
                setKycModalFlag={setKycModalFlag}
                setMobilePhoneModalFlag={setMobilePhoneModalFlag}
            />

            {userNameWithTypeTitle ? (
                <span className="mt-2 mb-6 d-block text-22 fw-500">
                    {formatMessage(defineText.hello)} {userNameWithTypeTitle},
                </span>
            ) : null}

            {StatusAdequacy !== TILE_STATUS.HIDE && StatusAdequacy !== TILE_STATUS.SUCCESS && (
                <WrappedAdequacyModal
                    onCloseButtonClick={
                        StatusAdequacy == "renew"
                            ? () => {
                                  setSettingsModalOpen(false);
                                  setAdequacyModalFlag(false);
                                  onAccountModalClose();
                              }
                            : () => {
                                  setAdequacyModalFlag(false);
                                  setSettingsModalOpen(false);
                                  onAccountModalClose();
                              }
                    }
                    StatusAdequacy={StatusAdequacy}
                    adequacyModalFlag={adequacyModalFlag}
                    setAdequacyModalFlag={setAdequacyModalFlag}
                />
            )}

            {currentKYCStatus !== KYC_STATUS_LOCAL.COMPLETED && (
                <WrappedKycModal
                    onCloseButtonClick={() => {
                        setKycModalFlag(false);
                        onAccountModalClose();
                    }}
                    currentKYCStatus={currentKYCStatus}
                    kycModalFlag={kycModalFlag}
                    setKycModalFlag={setKycModalFlag}
                    addHash={(hash) => addHash(hash)}
                    removeHash={removeHash}
                />
            )}

            {StatusMobilePhone === TILE_STATUS.SHOW && mobilePhoneCompleted === TILE_STATUS.FALSE && (
                <WrappedMobileModal
                    onCloseButtonClick={() => {
                        setMobilePhoneModalFlag(false);
                        onAccountModalClose();
                    }}
                    setMobilePhoneModalFlag={setMobilePhoneModalFlag}
                    mobilePhoneModalFlag={mobilePhoneModalFlag}
                />
            )}
            <WrappedMangopayWallet
                onCloseButtonClick={onAccountModalClose}
                openSmModal={openSmModal}
                isMainAccountModalOpen={isMainAccountModalOpen}
                setMainAccountModalOpen={setMainAccountModalOpen}
                addHash={(hash) => addHash(hash)}
            />
            {titlesData.map((tile, index) => {
                if (tile.isRender) {
                    return <Row title={tile.title} svg={tile.svg} onClick={tile.onClick} svgClass={tile.svgClass} key={index} />;
                }
            })}
        </SideModal>
    );
};

const mapStateToProps = createStructuredSelector({
    userEmail: selectEmail,
    isLoggedIn: selectIsLoggedIn,
    userDashboardData: selectUserDashboardDetails,
    unreadCount: getUnreadCount,
    currentTenant: selectTenant,
    currentKYCStatus: selectCurrenKYCStatus,
    userNameWithTypeTitle: selectUserNameWithTypeTitle,
});

export default connect(mapStateToProps)(WrappedAccountModal);
