import React, { Fragment, useState, useEffect } from "react";
import { useFormattedMessage } from "hooks";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import MessageTile from "./MessageTile";
import { fetchMessagesStart } from "store/user/message/actions";
import { getIsLoading, getTotalMessages } from "store/user/message/selectors";
import NoNotification from "assets/images/svg/no-notification.svg";
import NewLoader from "components/NewLoader";
import Toggle from "react-toggle";

const defineText = {
    messages: "notification_messages",
    noNewMessages: "no_new_messages",
    loadMoreNews: "load_more_news",
    unread: "unread",
};

const MessagesModal = ({
    messages,
    setStep,
    setMessage,
    step,
    fetchMessagesStart,
    totalMessages,
    isLoading,
    offset,
    setOffset,
    open,
    backSlideEffect = "",
    setBackSlideEffect,
    isStepChanged = false,
}) => {
    const { formatMessage } = useFormattedMessage();
    const [filteredItems, setFilteredItems] = useState(messages);
    const [hasShowUnreadMessagesOnly, setShowUnreadMessagesOnly] = useState(false);
    const [isLoadMore, setLoadMore] = useState(false);

    useEffect(() => {
        !open && offset === 1 && fetchMessagesStart(offset, 10);
    }, []);

    useEffect(() => {
        !isStepChanged && open && offset === 1 && fetchMessagesStart(offset, 10);
    }, [open, isStepChanged]);

    useEffect(() => {
        if (hasShowUnreadMessagesOnly) {
            const unreadMessages = messages?.filter((item) => !item.readDate && item);
            setFilteredItems(unreadMessages);
        } else {
            setFilteredItems(messages);
        }
    }, [messages, hasShowUnreadMessagesOnly]);

    useEffect(() => {
        !isLoading && setLoadMore(false);
    }, [isLoading]);

    const onClick = () => {
        setLoadMore(true);
        setOffset(offset + 1);
        fetchMessagesStart(offset + 1, 10);
    };

    return (
        <div className={backSlideEffect}>
            {isLoading && !isLoadMore ? (
                <div className="editable-modal mt-10">
                    <NewLoader type="primary" className="editable-modal-loader" />
                </div>
            ) : messages.length ? (
                <Fragment>
                    <div className="d-flex align-center justify-end mb-4">
                        <span className="fw-500 text-16 mr-2">{formatMessage(defineText.unread)}</span>
                        <Toggle
                            className="toggle-class toggle-item"
                            onChange={() => setShowUnreadMessagesOnly(!hasShowUnreadMessagesOnly)}
                            checked={hasShowUnreadMessagesOnly}
                            icons={false}
                        />
                    </div>
                    {filteredItems.map((item, index) => (
                        <MessageTile
                            message={item}
                            messages={messages}
                            key={"inbox" + index}
                            setMessage={setMessage}
                            onMessageClick={() => {
                                setBackSlideEffect("step-slide-left");
                                setStep(step + 1);
                            }}
                        />
                    ))}
                    {totalMessages > messages.length && (
                        <div className="flex mt-5 justify-center">
                            {isLoadMore && isLoading ? (
                                <NewLoader type="primary" />
                            ) : (
                                <span className="text-12 pointer primary-color" onClick={onClick}>
                                    {formatMessage(defineText.loadMoreNews)} <i className="fas fa-angle-double-right" />
                                </span>
                            )}
                        </div>
                    )}
                </Fragment>
            ) : (
                <div className="no-new-notification px-5 py-8 d-flex align-center justify-center">
                    <NoNotification />
                    <span className="text-14">{formatMessage(defineText.noNewMessages)}</span>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    totalMessages: getTotalMessages,
    isLoading: getIsLoading,
});

const mapDispatchToProps = (dispatch) => ({
    fetchMessagesStart: (offset, limit, search) => dispatch(fetchMessagesStart(offset, limit, search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessagesModal);
