const AccountActionTypes = {
    USER_DASHBOARD_DETAILS_START: "USER_DASHBOARD_DETAILS_START",
    USER_DASHBOARD_DETAILS_SUCCESS: "USER_DASHBOARD_DETAILS_SUCCESS",
    USER_DASHBOARD_DETAILS_FAILURE: "USER_DASHBOARD_DETAILS_FAILURE",

    FETCH_USER_DETAILS_START: "FETCH_USER_DETAILS_START",
    FETCH_USER_DETAILS_SUCCESS: "FETCH_USER_DETAILS_SUCCESS",
    FETCH_USER_DETAILS_FAILURE: "FETCH_USER_DETAILS_FAILURE",

    SET_USER_MOBILE_DETAILS_START: "SET_USER_MOBILE_DETAILS_START",
    SET_USER_MOBILE_DETAILS_SUCCESS: "SET_USER_MOBILE_DETAILS_SUCCESS",
    SET_USER_MOBILE_DETAILS_FAILURE: "SET_USER_MOBILE_DETAILS_FAILURE",

    FETCH_ALL_USER_DETAILS_START: "FETCH_ALL_USER_DETAILS_START",
    FETCH_ALL_USER_DETAILS_SUCCESS: "FETCH_ALL_USER_DETAILS_SUCCESS",
    FETCH_ALL_USER_DETAILS_FAILURE: "FETCH_ALL_USER_DETAILS_FAILURE",

    SET_USER_FIELD_VALUE: "SET_USER_FIELD_VALUE",
    SET_AUTH_TOKEN_DATA: "SET_AUTH_TOKEN_DATA",
    LOGOUT: "LOGOUT",
    SET_USER_MOBILE_DATA: "SET_USER_MOBILE_DATA",
    SET_TIPSTER_DATA: "SET_TIPSTER_DATA",
    SET_ONBOARDING_PROCESS_STATUS: "SET_ONBOARDING_PROCESS_STATUS",
    UPDATE_USER_DETAIL_FIELD: "UPDATE_USER_DETAIL_FIELD",
    SET_TIPSTER_PDF_DOWNLOADED: "SET_TIPSTER_PDF_DOWNLOADED",
    SET_ONBOARDING_PROCESS_STEP_DATA: "SET_ONBOARDING_PROCESS_STEP_DATA",

    FETCH_PAYMENTS_FOR_USER_START: "FETCH_PAYMENTS_FOR_USER_START",
    FETCH_PAYMENTS_FOR_USER_SUCCESS: "FETCH_PAYMENTS_FOR_USER_SUCCESS",
    FETCH_PAYMENTS_FOR_USER_FAILURE: "FETCH_PAYMENTS_FOR_USER_FAILURE",

    USER_MIN_PAYIN_AMOUNT_START: "USER_MIN_PAYIN_AMOUNT_START",
    USER_MIN_PAYIN_AMOUNT_SUCCESS: "USER_MIN_PAYIN_AMOUNT_SUCCESS",
    USER_MIN_PAYIN_AMOUNT_FAILURE: "USER_MIN_PAYIN_AMOUNT_FAILURE",

    FETCH_PAY_IN_WALLET_BALANCE_START: "FETCH_PAY_IN_WALLET_BALANCE_START",
    FETCH_PAY_IN_WALLET_BALANCE_SUCCESS: "FETCH_PAY_IN_WALLET_BALANCE_SUCCESS",
    FETCH_PAY_IN_WALLET_BALANCE_FAILURE: "FETCH_PAY_IN_WALLET_BALANCE_FAILURE",

    FETCH_BLOCKED_WALLET_BALANCE_START: "FETCH_BLOCKED_WALLET_BALANCE_START",
    FETCH_BLOCKED_WALLET_BALANCE_SUCCESS: "FETCH_BLOCKED_WALLET_BALANCE_SUCCESS",
    FETCH_BLOCKED_WALLET_BALANCE_FAILURE: "FETCH_BLOCKED_WALLET_BALANCE_FAILURE",

    SET_USER_REGISTRATION_ON_BOARDING_STATUS: "SET_USER_REGISTRATION_ON_BOARDING_STATUS",

    SET_CAN_MAKE_CALL: "SET_CAN_MAKE_CALL",
    UPDATE_PRIMARY_PHONE_STATUS: "UPDATE_PRIMARY_PHONE_STATUS",
    UPDATE_USER_MANGOPAY_KYC_LEVEL: "UPDATE_USER_MANGOPAY_KYC_LEVEL",

    UPDATE_CAMPAGIN_START: "UPDATE_CAMPAGIN_START",
    UPDATE_CAMPAGIN_SUCCESS: "UPDATE_CAMPAGIN_SUCCESS",

    UPDATE_USER_TAX_DATA_TRANSITIONS: "UPDATE_USER_TAX_DATA_TRANSITIONS",

    UPDATE_USER_TAX_DATA_START: "UPDATE_USER_TAX_DATA_START",

    UPDATE_USER_DATA: "UPDATE_USER_DATA",

    FETCH_USER_TERMS_AND_CONDITIONS_SUCCESS: "FETCH_USER_TERMS_AND_CONDITIONS_SUCCESS",

    FETCH_USER_SUBSCRIPTIONS_START: "FETCH_USER_SUBSCRIPTIONS_START",
    FETCH_USER_SUBSCRIPTIONS_SUCCESS: "FETCH_USER_SUBSCRIPTIONS_SUCCESS",
    FETCH_USER_SUBSCRIPTIONS_FAILURE: "FETCH_USER_SUBSCRIPTIONS_FAILURE",
};

export default AccountActionTypes;
