const KYCActionTypes = {
    SET_KYC_START: "SET_KYC_START",
    SET_KYC_SUCCESS: "SET_KYC_SUCCESS",
    SET_KYC_FAILURE: "SET_KYC_FAILURE",

    FETCH_KYC_START: "FETCH_KYC_START",
    FETCH_KYC_SUCCESS: "FETCH_KYC_SUCCESS",
    FETCH_KYC_FAILURE: "FETCH_KYC_FAILURE",

    FETCH_KYC_FROM_PROVIDER_START: "FETCH_KYC_FROM_PROVIDER_START",
    FETCH_KYC_FROM_PROVIDER_SUCCESS: "FETCH_KYC_FROM_PROVIDER_SUCCESS",
    FETCH_KYC_FROM_PROVIDER_FAILURE: "FETCH_KYC_FROM_PROVIDER_FAILURE",

    FETCH_IDNOW_START: "FETCH_IDNOW_START",
    FETCH_IDNOW_SUCCESS: "FETCH_IDNOW_SUCCESS",
    FETCH_IDNOW_FAILURE: "FETCH_IDNOW_FAILURE",

    FETCH_POSTIDENT_START: "FETCH_POSTIDENT_START",
    FETCH_POSTIDENT_SUCCESS: "FETCH_POSTIDENT_SUCCESS",
    FETCH_POSTIDENT_FAILURE: "FETCH_POSTIDENT_FAILURE",

    FETCH_REKYC_START: "FETCH_REKYC_START",
    FETCH_REKYC_SUCCESS: "FETCH_REKYC_SUCCESS",
    FETCH_REKYC_FAILURE: "FETCH_REKYC_FAILURE",

    SET_REKYC_START: "SET_REKYC_START",
    SET_REKYC_SUCCESS: "SET_REKYC_SUCCESS",
    SET_REKYC_FAILURE: "SET_REKYC_FAILURE",

    UPDATE_KYC_FORM_DATA: "UPDATE_KYC_FORM_DATA",
    RESET_KYC_FORM_DATA: "RESET_KYC_FORM_DATA",

    CALL_INITIAL_KYC_METHODS: "CALL_INITIAL_KYC_METHODS",

    //ADMIN
    FETCH_KYC_START_ADMIN: "FETCH_KYC_START_ADMIN",
    FETCH_KYC_SUCCESS_ADMIN: "FETCH_KYC_SUCCESS_ADMIN",
    FETCH_KYC_FAILURE_ADMIN: "FETCH_KYC_FAILURE_ADMIN",

    UPDATE_KYC_ADMIN: "UPDATE_KYC_ADMIN",

    FETCH_REKYC_START_ADMIN: "FETCH_REKYC_START_ADMIN",
    FETCH_REKYC_SUCCESS_ADMIN: "FETCH_REKYC_SUCCESS_ADMIN",
    FETCH_REKYC_FAILURE_ADMIN: "FETCH_REKYC_FAILURE_ADMIN",

    FETCH_KYC_SERVER_ERROR: "FETCH_KYC_SERVER_ERROR",
    CALL_INHERIT_USER_INITIAL_KYC_METHOD: "CALL_INHERIT_USER_INITIAL_KYC_METHOD",

    FETCH_KYC_ALL_DETAILS_START: "FETCH_KYC_ALL_DETAILS_START",

    FETCH_KYC_STATUS_START: "FETCH_KYC_STATUS_START",
    FETCH_KYC_STATUS_SUCCESS: "FETCH_KYC_STATUS_SUCCESS",
    FETCH_KYC_STATUS_FAILURE: "FETCH_KYC_STATUS_FAILURE",
};

export default KYCActionTypes;
