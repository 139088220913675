import { KYC_STATUS_LOCAL, PROVIDER_STATUS } from "constants/status";
import { createSelector } from "reselect";

const getKYC = (state) => state.kyc.kycData;
const getKYCLoading = (state) => state.kyc.isSubmittingKYC;
const getKYCError = (state) => state.kyc.error;
export const getUserInitiated = (state) => state.kyc.hasUserInitiated;
const getUserIDnowStatus = (state) => state.kyc.idNowStatus;
const getUserKYCStatus = (state) => state.kyc.statusKYC;
const getReKycStatus = (state) => state.kyc.reKYC;
const getUserKYCed = (state) => state.kyc.isUserKYCed;
const getKycFormData = (state) => state.kyc.kycFormData;
const getKycProvider = (state) => state.kyc?.providerData;

const getUserKYCServerError = (state) => state.kyc.isKYCServerError;

//ADMIN
const getAdminKYC = (state) => state.kyc.adminSelectedUser.kycData;
const getAdminUserInitiated = (state) => state.kyc?.adminSelectedUser?.hasUserInitiated;
const getAdminUserIDnowStatus = (state) => state.kyc?.adminSelectedUser?.idNowStatus;
const getAdminReKycStatus = (state) => state.kyc?.adminSelectedUser?.reKYC;
const getAdminUserKYCed = (state) => state.kyc?.adminSelectedUser?.isUserKYCed;
const getAdminKycProvider = (state) => state.kyc.adminSelectedUser?.providerData;
const getKycFormDataAdmin = (state) => state.kyc.adminSelectedUser?.kycFormData;
const getKycAdminLoading = (state) => state.kyc.adminSelectedUser?.isLoading;

const finalizeKYCStatus = (hasUserInitiated, isUserKYCed, reKYC, idNowStatus, statusKYC) => {
    let status = KYC_STATUS_LOCAL.INCOMPLETE;
    if (isUserKYCed && !reKYC) {
        status = KYC_STATUS_LOCAL.COMPLETED;
    } else if (reKYC) {
        status =
            idNowStatus == PROVIDER_STATUS.CREATED || statusKYC === PROVIDER_STATUS.CREATED
                ? KYC_STATUS_LOCAL.INITIATED
                : idNowStatus == PROVIDER_STATUS.PENDING || statusKYC === PROVIDER_STATUS.PENDING
                  ? KYC_STATUS_LOCAL.REKYC_PENDING
                  : idNowStatus == PROVIDER_STATUS.FAILURE || statusKYC === PROVIDER_STATUS.FAILURE
                    ? KYC_STATUS_LOCAL.ERROR
                    : KYC_STATUS_LOCAL.REKYC;
    } else if (idNowStatus == PROVIDER_STATUS.PENDING || statusKYC === PROVIDER_STATUS.PENDING) {
        status = KYC_STATUS_LOCAL.PENDING;
    } else if (idNowStatus == PROVIDER_STATUS.FAILURE || statusKYC === PROVIDER_STATUS.FAILURE) {
        status = KYC_STATUS_LOCAL.ERROR;
    } else if (hasUserInitiated) {
        status = KYC_STATUS_LOCAL.INITIATED;
    }
    return status;
};

export const selectKYC = createSelector(getKYC, (kycData) => kycData);

export const selectKycFormData = createSelector(getKycFormData, (kycData) => kycData);

export const selectKycProviderData = createSelector(getKycProvider, (providerData) => providerData);

export const selectKYCSubmitting = createSelector(getKYCLoading, (isSubmittingKYC) => isSubmittingKYC);
export const selectKYCError = createSelector(getKYCError, (error) => error);
export const selectKYCIsAccepted = createSelector(getKYC, (kyc) => kyc?.isAccepted);
export const selectHasUserInitiated = createSelector(getUserInitiated, (hasInitiated) => hasInitiated);
export const selectHasUserInitiatedAdmin = createSelector(getAdminUserInitiated, (hasInitiated) => hasInitiated);

export const selectIDnowStatus = createSelector(getKYC, (kyc) => kyc?.idNowStatus);
export const selectPostIdentStatus = createSelector(getKYC, (kyc) => kyc?.submittedPostIdent);
export const selectIDnowRedirectURL = createSelector(getKYC, (kyc) => kyc?.idNowRedirectUrl);

export const selectCurrenKYCStatus = createSelector(
    [getUserInitiated, getUserKYCed, getReKycStatus, getUserIDnowStatus, getUserKYCStatus],
    finalizeKYCStatus,
);

export const selectKYCStatusShow = createSelector(selectCurrenKYCStatus, (currentKYCStatus) => {
    return currentKYCStatus !== KYC_STATUS_LOCAL.INCOMPLETE;
});

export const selectKYCCompleted = createSelector(selectCurrenKYCStatus, (currentKYCStatus) => {
    return currentKYCStatus === KYC_STATUS_LOCAL.COMPLETED;
});

//ADMIN
export const selectKycFormDataAdmin = createSelector(getKycFormDataAdmin, (kycFormData) => kycFormData);
export const selectAdminKYC = createSelector(getAdminKYC, (kycData) => kycData);
export const selectAdminReKYCStatus = createSelector(getAdminReKycStatus, (reKYC) => reKYC);
export const selectAdminKYCProvider = createSelector(getAdminKycProvider, (providerData) => providerData?.Provider);
export const selectAdminKYCProviderData = createSelector(getAdminKycProvider, (providerData) => providerData);
export const selectAdminKYCLoading = createSelector(getKycAdminLoading, (isLoading) => isLoading);

export const selectKYCServerError = createSelector(getUserKYCServerError, (isKYCServerError) => isKYCServerError);

export const selectCurrenKYCStatusAdmin = createSelector(
    [getAdminUserInitiated, getAdminUserKYCed, getAdminReKycStatus, getAdminUserIDnowStatus],
    finalizeKYCStatus,
);

export const selectKYCStatusShowAdmin = createSelector(selectCurrenKYCStatusAdmin, (currentKYCStatus) => {
    return (
        currentKYCStatus !== KYC_STATUS_LOCAL.INCOMPLETE ||
        currentKYCStatus !== KYC_STATUS_LOCAL.INITIATED ||
        currentKYCStatus !== KYC_STATUS_LOCAL.REKYC
    );
});
