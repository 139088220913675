import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import cn from "classnames";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { setLocale } from "store/app/actions";
import { exitInheritUser } from "store/user/account/actions";
import Cookies from "js-cookie";
import NavLink from "components/NavLink";
import Close from "components/Close";
import { useFormattedMessage, useUtils } from "hooks";
import Contact from "assets/images/svg/contact.svg";
import ArrowDown from "assets/images/svg/arrow-down.svg";
import LogoSvg from "assets/images/svg/FINEXITY_logo_black.svg";
import { findRootDomain, getTenantAssets } from "utils";
import { logoutTitle } from "i18n/messages/accountMenu";
import Auth from "utils/auth0";
import { WEB_FLOW_ROUTES, PUBLIC_NAVIGATION_ROUTES, TENANTS_LIST, TENANTS_WITH_REGISTRATION_DISABLED } from "constants/index";
import { contactNumberLink, contactNumberText } from "constants/general_constants";
import {
    selectIsLoggedIn,
    selectIsUserFetched,
    selectIsUserOnboardingCompleted,
    selectIsUserStatusEmailVerified,
    selectUserStatusEmail,
    selectUserTermsAndConditionsAccepted,
} from "store/user/account/selectors";
import { selectTenant, selectCurrentTenant, selectLocale } from "store/app/selectors";
import i18nInstance from "i18n/i18n";

const FINX_COOKIE = require("common/constants");

const messages = {
    german: "lp_nav_german",
    english: "lp_nav_english",
    phone: "phone_number",
    or: "auth_account_or",
    login: "auth_login",
    register: "register",
    maMarketplace: "marketplace",
    maCompany: "company",
    maDigitalWealth: "digital_wealth",
    maRealEstate: "real_estate",
    maArt: "art",
    maClassicCars: "classic_cars",
    maOurInvestmentPhilosophy: "our_investment_philosophy",
    maOurDueDiligence: "our_due_diligence",
    maPress: "news_room",
    maAboutUs: "about",
    maOurTeam: "our_team",
    maCareers: "careers",
    maContactUs: "contact",
    fineWine: "fine_wine",
    exit: "exit",
    diamonds: "diamonds",
    privateEquity: "private_equity",
    stringInstruments: "string_instruments",
    watches: "watches",
    realEstateInvestment: "real_estate_investment",
    assetClasses: "asset_classes",
    faq: "faq",
};

const isInheritUser = !!Cookies.get(FINX_COOKIE.INHERIT_USER_TOKEN);

const Header = ({
    locale,
    isLoggedIn,
    setLocale,
    isEmailVerified,
    currentTenant,
    isUserFetched,
    isUserOnboardingCompleted,
    isUserTermsAndConditionsAccepted,
    tenant,
}) => {
    const { asPath } = useRouter();
    const { formatMessage } = useFormattedMessage();
    const { documentHost, isTenantFinexity } = useUtils();
    const isTenantVolksimmoinvest = currentTenant === TENANTS_LIST.VOLKSIMMOINVEST;
    const [isHamburgerMenuCollapsed, setIsHamburgerMenuCollapsed] = useState(true);

    const closeSubMenu = () => {
        if (window.outerWidth < 992) {
            jQuery(".has-children").removeClass("active");
            jQuery(".sub-menu").slideUp();
        }
    };

    // Remove margin space when smart banner exist
    useEffect(() => {
        const space = setInterval(() => {
            jQuery(".smartbanner").closest("body").find("#mainNavigation").css("margin-top", "84px");
            jQuery(".smartbanner").closest("body").find("#headerMenuWrapper").css("margin-top", "84px");
        }, 100);
        return () => clearInterval(space);
    }, []);

    useEffect(() => {
        if (isHamburgerMenuCollapsed) {
            document.body.classList.remove("hidden");
            closeSubMenu();
        } else {
            document.body.classList.add("hidden");
        }
    }, [isHamburgerMenuCollapsed]);

    useEffect(() => {
        closeSubMenu();
    }, [asPath]);

    useEffect(() => {
        // Mobile submenu toggle
        window.outerWidth < 992 &&
            jQuery(".has-children").click(function () {
                console.log("called");
                // e.preventDefault();
                const $this = jQuery(this);
                if (!$this.hasClass("active")) {
                    console.log("coming in if");
                    closeSubMenu();
                    $this.addClass("active");
                    $this.find(".sub-menu").slideDown();
                } else {
                    console.log("coming in else");
                    closeSubMenu();
                }
            });
    }, []);

    useEffect(() => {
        // Desktop submenu toggle
        window.outerWidth > 991 &&
            jQuery(".has-children").hover(
                function () {
                    $(this).addClass("active");
                },
                function () {
                    $(this).removeClass("active");
                },
            );
    }, [asPath, locale]);

    useEffect(() => {
        const header = document.getElementById("mainNavigation");
        const sticky = header.offsetTop;
        const body = document.body;

        function stickyHeader() {
            if (window.pageYOffset > sticky) {
                body.classList.add("sticky");
            } else {
                body.classList.remove("sticky");
            }
        }

        // Sticky header
        if (window.outerWidth > 991 && isTenantFinexity) {
            window.onscroll = function () {
                stickyHeader();
            };
        }
    }, []);

    function setLanguage(locale) {
        i18nInstance.changeLanguage(locale);
        setLocale(locale);
    }

    function toggleHamburgerMenu() {
        setIsHamburgerMenuCollapsed(!isHamburgerMenuCollapsed);
    }

    function collapseHamburgerMenu() {
        setIsHamburgerMenuCollapsed(true);
    }

    const isLanguageSwitcherVisible =
        !asPath.startsWith("/marketplace") && !asPath.startsWith("/multiasset1") && !asPath.startsWith("/digital-investieren");

    const LanguageSwitcher = ({ className = "" }) => (
        <ul className={`lang d-flex ${className}`}>
            <li
                onClick={() => setLanguage("de")}
                className={cn({
                    "lang-item": true,
                    "new-link medium": true,
                    active: locale === "de",
                })}
                onKeyPress={() => setLanguage("de")}
                role="button"
                tabIndex={0}
            >
                <span>{formatMessage(messages.german)}</span>
            </li>
            <li
                onClick={() => setLanguage("en")}
                className={cn({
                    "lang-item": true,
                    "new-link medium mr-0": true,
                    active: locale === "en",
                })}
                onKeyPress={() => setLanguage("en")}
                role="button"
                tabIndex={0}
            >
                <span>{formatMessage(messages.english)}</span>
            </li>
        </ul>
    );

    const tenantLogoLink = getTenantAssets(documentHost, currentTenant).tenantLogo;
    const logoImgAlt = currentTenant?.toUpperCase() || "FINEXITY";

    const isRegister = asPath.startsWith("/register");
    const isUserOnboarding = asPath.startsWith("/user-onboarding");
    const isUserOnEmailConfirmation = asPath.startsWith("/email-confirmation");
    const isUserOnTermsAndCondition = asPath.startsWith("/retail/accept-terms-conditions");

    const onLogout = () => {
        if (isInheritUser) {
            exitInheritUser();
        } else {
            Auth.customLogout();
        }
    };

    return (
        <header
            className={`broad-container multi-asset-header ${isRegister && "register-header"} ${
                isUserOnboarding && "user-onboarding-header"
            } ${isTenantFinexity ? "" : "tenant-header"}`}
        >
            <div id="mainNavigation" className=" broad-container bottom-part bg-white d-flex justify-between align-center">
                <NavLink
                    isForcePushed
                    href={tenant?.RootDomainURLEnabled === "yes" && tenant?.Domain ? findRootDomain(tenant?.Domain) : "/"}
                    className={`logo ${isTenantFinexity ? "mr-13 d-flex align-center" : "mr-16"}`}
                    onClick={collapseHamburgerMenu}
                >
                    {isTenantFinexity ? <LogoSvg width={108} height={16} /> : <img src={tenantLogoLink} alt={logoImgAlt} />}
                </NavLink>
                {!isRegister && !isUserOnboarding && !isUserOnTermsAndCondition && !isUserOnEmailConfirmation ? (
                    <nav
                        id="headerMenuWrapper"
                        className={cn({
                            "header__menu-wrapper flex-row": true,
                            open: !isHamburgerMenuCollapsed,
                            "health-care-nav": isTenantVolksimmoinvest,
                        })}
                    >
                        <ul className="bottom-menu flex-row">
                            {isTenantFinexity ? (
                                <>
                                    <li className={`li-menu-item ${asPath.startsWith("/marketplace") ? "blue-line" : ""}`}>
                                        {isTenantFinexity ? (
                                            <NavLink
                                                isForcePushed
                                                href={WEB_FLOW_ROUTES.MARKETPLACE}
                                                className="new-link medium d-inline-block parent-link inner-item"
                                                activeClassName="active"
                                                forceActive={asPath.startsWith("/marketplace")}
                                                onClick={collapseHamburgerMenu}
                                            >
                                                {formatMessage(messages.maMarketplace)}
                                            </NavLink>
                                        ) : null}
                                    </li>
                                    <li
                                        className={`li-menu-item single has-children ${
                                            asPath.startsWith("/company") ? "blue-line" : ""
                                        }`}
                                    >
                                        <span className="d-flex align-center inner-item">
                                            <span
                                                className={`new-link medium d-inline-block parent-link ${
                                                    asPath.startsWith("/company") ? "active" : ""
                                                }`}
                                            >
                                                {formatMessage(messages.maCompany)}
                                            </span>
                                            <span className="svg-container">
                                                <ArrowDown />
                                            </span>
                                        </span>
                                        <div className="sub-menu">
                                            <div className="inner-sub-menu">
                                                <ul>
                                                    <li className="li-menu-item">
                                                        <NavLink
                                                            isForcePushed
                                                            href={PUBLIC_NAVIGATION_ROUTES.MANAGEMENT_TEAM[locale].url}
                                                            className="new-link medium d-inline-block"
                                                            onClick={collapseHamburgerMenu}
                                                            activeClassName="active"
                                                        >
                                                            {PUBLIC_NAVIGATION_ROUTES.MANAGEMENT_TEAM[locale].title}
                                                        </NavLink>
                                                    </li>
                                                    <li className="li-menu-item">
                                                        <NavLink
                                                            isForcePushed
                                                            href={PUBLIC_NAVIGATION_ROUTES.BLOG[locale].url}
                                                            className="new-link medium d-inline-block"
                                                            onClick={collapseHamburgerMenu}
                                                            activeClassName="active"
                                                        >
                                                            {PUBLIC_NAVIGATION_ROUTES.BLOG[locale].title}
                                                        </NavLink>
                                                    </li>
                                                    <li className="li-menu-item">
                                                        <NavLink
                                                            isForcePushed
                                                            href={PUBLIC_NAVIGATION_ROUTES.PRESS[locale].url}
                                                            className="new-link medium d-inline-block"
                                                            onClick={collapseHamburgerMenu}
                                                            activeClassName="active"
                                                        >
                                                            {PUBLIC_NAVIGATION_ROUTES.PRESS[locale].title}
                                                        </NavLink>
                                                    </li>
                                                    <li className="li-menu-item">
                                                        <NavLink
                                                            isForcePushed
                                                            href={PUBLIC_NAVIGATION_ROUTES.CAREER[locale].url}
                                                            className="new-link medium d-inline-block"
                                                            onClick={collapseHamburgerMenu}
                                                            activeClassName="active"
                                                        >
                                                            {PUBLIC_NAVIGATION_ROUTES.CAREER[locale].title}
                                                        </NavLink>
                                                    </li>
                                                    <li className="li-menu-item">
                                                        <NavLink
                                                            isForcePushed
                                                            href={PUBLIC_NAVIGATION_ROUTES.FAQ[locale].url}
                                                            className="new-link medium d-inline-block"
                                                            onClick={collapseHamburgerMenu}
                                                            activeClassName="active"
                                                        >
                                                            {PUBLIC_NAVIGATION_ROUTES.FAQ[locale].title}
                                                        </NavLink>
                                                    </li>
                                                    <li className="li-menu-item">
                                                        <NavLink
                                                            isForcePushed
                                                            href={PUBLIC_NAVIGATION_ROUTES.SUPPORT[locale].url}
                                                            className="new-link medium d-inline-block"
                                                            onClick={collapseHamburgerMenu}
                                                            activeClassName="active"
                                                        >
                                                            {PUBLIC_NAVIGATION_ROUTES.SUPPORT[locale].title}
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </>
                            ) : isTenantVolksimmoinvest ? (
                                <>
                                    <li className={`li-menu-item ${asPath.startsWith("/marketplace") ? "blue-line" : ""}`}>
                                        <NavLink
                                            isForcePushed
                                            href={WEB_FLOW_ROUTES.MARKETPLACE}
                                            className="new-link d-inline-block parent-link inner-item text-uppercase"
                                            activeClassName="active"
                                            forceActive={asPath.startsWith("/marketplace")}
                                            onClick={collapseHamburgerMenu}
                                        >
                                            {formatMessage(messages.maMarketplace)}
                                        </NavLink>
                                    </li>
                                    <li className={`li-menu-item ${asPath.startsWith("/assetklassen") ? "blue-line" : ""}`}>
                                        <NavLink
                                            isForcePushed
                                            href={WEB_FLOW_ROUTES.ASSET_CLASS}
                                            className="new-link d-inline-block parent-link inner-item text-uppercase"
                                            activeClassName="active"
                                            forceActive={asPath.startsWith("/assetklassen")}
                                            onClick={collapseHamburgerMenu}
                                        >
                                            {formatMessage(messages.assetClasses)}
                                        </NavLink>
                                    </li>
                                    <li className={`li-menu-item ${asPath.startsWith("/ueber-uns") ? "blue-line" : ""}`}>
                                        <NavLink
                                            isForcePushed
                                            href={WEB_FLOW_ROUTES.ABOUT_US}
                                            className="new-link d-inline-block parent-link inner-item text-uppercase"
                                            activeClassName="active"
                                            forceActive={asPath.startsWith("/ueber-uns")}
                                            onClick={collapseHamburgerMenu}
                                        >
                                            {formatMessage(messages.maAboutUs)}
                                        </NavLink>
                                    </li>
                                    <li className={`li-menu-item ${asPath.startsWith("/kontakt") ? "blue-line" : ""}`}>
                                        <NavLink
                                            isForcePushed
                                            href={WEB_FLOW_ROUTES.FH_CONTACT}
                                            className="new-link d-inline-block parent-link inner-item text-uppercase"
                                            activeClassName="active"
                                            forceActive={asPath.startsWith("/kontakt")}
                                            onClick={collapseHamburgerMenu}
                                        >
                                            {formatMessage(messages.maContactUs)}
                                        </NavLink>
                                    </li>
                                </>
                            ) : null}
                        </ul>
                        <div className="header__right-menu flex-row align-center">
                            {isTenantFinexity && (
                                <a href={`tel:${contactNumberLink}`} className="flex align-center phone-wrapper new-link medium">
                                    <i className="ico-phone">
                                        <Contact />
                                    </i>
                                    {contactNumberText}
                                </a>
                            )}
                            {isLanguageSwitcherVisible && (
                                <div className="mobile-display">
                                    <LanguageSwitcher />
                                </div>
                            )}
                            {isLoggedIn ? (
                                <>
                                    {(isEmailVerified || asPath !== "/email-confirmation") && asPath !== "/provide-email" ? (
                                        <NavLink
                                            href={
                                                isUserFetched
                                                    ? isEmailVerified
                                                        ? !isUserOnboardingCompleted
                                                            ? "/user-onboarding"
                                                            : isUserTermsAndConditionsAccepted
                                                              ? "/dashboard"
                                                              : "/retail/accept-terms-conditions"
                                                        : "/email-confirmation"
                                                    : ""
                                            }
                                        >
                                            <button
                                                className="mb-0 small dashboard-btn"
                                                tabIndex={0}
                                                onClick={collapseHamburgerMenu}
                                            >
                                                Dashboard
                                            </button>
                                        </NavLink>
                                    ) : (
                                        <NavLink href="#" className="">
                                            <button
                                                className="mb-0 small cta-inverse logout-link"
                                                tabIndex={0}
                                                onClick={onLogout}
                                            >
                                                {isInheritUser ? "Exit" : formatMessage(logoutTitle)}
                                            </button>
                                        </NavLink>
                                    )}
                                    <div className="desktop-display ml-4">
                                        <LanguageSwitcher />
                                    </div>
                                </>
                            ) : (
                                <div className="d-flex justify-center align-center login-register">
                                    {isTenantVolksimmoinvest ? (
                                        <>
                                            <NavLink
                                                href="/register"
                                                className="btn-primary mb-0 medium"
                                                onClick={collapseHamburgerMenu}
                                            >
                                                {formatMessage(messages.register)}
                                            </NavLink>

                                            <NavLink
                                                href="/login"
                                                className="btn-primary mb-0 ml-5 medium tertiary"
                                                onClick={collapseHamburgerMenu}
                                            >
                                                {formatMessage(messages.login)}
                                            </NavLink>
                                        </>
                                    ) : (
                                        <>
                                            <NavLink
                                                href="/login"
                                                className="login-link primary-color new-link medium"
                                                onClick={collapseHamburgerMenu}
                                            >
                                                {formatMessage(messages.login)}
                                            </NavLink>
                                            {!TENANTS_WITH_REGISTRATION_DISABLED.includes(tenant?.TechnicalName) && (
                                                <>
                                                    <span className="new-link medium mx-2">
                                                        {formatMessage(messages.or).toLowerCase()}
                                                    </span>
                                                    <NavLink
                                                        href="/register"
                                                        className="register-link primary-color new-link medium"
                                                        onClick={collapseHamburgerMenu}
                                                    >
                                                        {formatMessage(messages.register)}
                                                    </NavLink>
                                                </>
                                            )}
                                            <NavLink href="/login" className="">
                                                <button
                                                    className="mb-0 medium login-btn"
                                                    tabIndex={0}
                                                    onClick={collapseHamburgerMenu}
                                                >
                                                    {formatMessage(messages.login)}
                                                </button>
                                            </NavLink>
                                        </>
                                    )}
                                    {isLanguageSwitcherVisible && (
                                        <div className="desktop-display ml-4">
                                            <LanguageSwitcher />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </nav>
                ) : (
                    <>
                        {isUserOnboarding || isUserOnTermsAndCondition || isUserOnEmailConfirmation ? (
                            <NavLink href="#" className="">
                                <button className="new-link medium flex align-center cta-inverse mb-0" onClick={onLogout}>
                                    <Close className="mr-2" /> {formatMessage(messages.exit)}
                                </button>
                            </NavLink>
                        ) : (
                            <NavLink
                                href="/"
                                className="new-link medium flex align-center"
                                onClick={() => {
                                    window.location = "/";
                                    collapseHamburgerMenu();
                                }}
                            >
                                <Close className="mr-2" /> {formatMessage(messages.exit)}
                            </NavLink>
                        )}
                    </>
                )}
                {!isRegister && !isUserOnboarding ? (
                    <span
                        className={cn({
                            "nav-icon pointer mobile-display": true,
                            open: !isHamburgerMenuCollapsed,
                        })}
                        onClick={toggleHamburgerMenu}
                        onKeyPress={toggleHamburgerMenu}
                        role="button"
                        tabIndex={0}
                    >
                        <span />
                        <span />
                        <span />
                        <span />
                    </span>
                ) : null}
            </div>
        </header>
    );
};

const mapStateToProps = createStructuredSelector({
    locale: selectLocale,
    isLoggedIn: selectIsLoggedIn,
    userStatusEmail: selectUserStatusEmail,
    currentTenant: selectTenant,
    isUserFetched: selectIsUserFetched,
    isEmailVerified: selectIsUserStatusEmailVerified,
    isUserTermsAndConditionsAccepted: selectUserTermsAndConditionsAccepted,
    isUserOnboardingCompleted: selectIsUserOnboardingCompleted,
    tenant: selectCurrentTenant,
});

const mapDispatchToProps = (dispatch) => ({
    setLocale: (locale) => dispatch(setLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
