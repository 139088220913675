import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectFilterUserRoles } from "store/user/account/selectors";

import DashBoardNavigation from "components/Navigation/DashBoardNavigation";
import WrappedAccountModal from "components/Account/WrappedAccountModal";

import { useRouter } from "next/router";

import { selectPlatformSourceFromApp } from "store/app/selectors";
import { addOverlay, removeOverlay, sideModalScrollHidden } from "utils";
import { useUtils } from "hooks";

const DashBoardHeader = () => {
    const router = useRouter();
    const { isTenantFinexity } = useUtils();
    const [isHamburgerMenuCollapsed, setIsHamburgerMenuCollapsed] = useState(true);
    const [isAccountModalOpen, setAccountModalOpen] = useState(false);
    const [contactModalFlag, setContactModalFlag] = useState(false);

    useEffect(() => {
        if (isAccountModalOpen) {
            addOverlay();
            sideModalScrollHidden(true);
        } else {
            removeOverlay();
            sideModalScrollHidden(false);
        }
    }, [isAccountModalOpen]);

    // Remove margin space when smart banner exist
    useEffect(() => {
        const space = setInterval(() => {
            jQuery(".smartbanner").closest("body").find("#headerDashboard").css("margin-top", "84px");
            jQuery(".smartbanner").closest("body").find("#headerMenuWrapper").css("margin-top", "84px");
        }, 100);
        return () => clearInterval(space);
    }, []);

    useEffect(() => {
        if (isHamburgerMenuCollapsed) {
            document.body.classList.remove("hidden");
        } else {
            document.body.classList.add("hidden");
        }
    }, [isHamburgerMenuCollapsed]);

    function toggleHamburgerMenu() {
        setIsHamburgerMenuCollapsed(!isHamburgerMenuCollapsed);
    }

    function collapseHamburgerMenu() {
        setIsHamburgerMenuCollapsed(true);
    }

    const isLocaleSwitchVisible = !router.pathname.startsWith("/admin");

    return (
        <>
            <WrappedAccountModal
                open={isAccountModalOpen}
                onCloseButtonClick={() => setAccountModalOpen(false)}
                contactModalFlag={contactModalFlag}
                setContactModalFlag={setContactModalFlag}
                setIsHamburgerMenuCollapsed={setIsHamburgerMenuCollapsed}
            />
            <header
                id="headerDashboard"
                className={`broad-container header dashboard-header flex justify-between align-center ${
                    isTenantFinexity ? "" : "tenant-header"
                }`}
            >
                <DashBoardNavigation
                    menuOpened={!isHamburgerMenuCollapsed}
                    toggleMenu={toggleHamburgerMenu}
                    closeMenu={collapseHamburgerMenu}
                    showI18n={isLocaleSwitchVisible}
                    pathname={router.pathname}
                    asPath={router.asPath}
                    setAccountModalOpen={setAccountModalOpen}
                    contactModalFlag={(value) => {
                        setAccountModalOpen(true);
                        setContactModalFlag(value);
                    }}
                    query={router.query}
                />
            </header>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    userRoles: selectFilterUserRoles,
    isApp: selectPlatformSourceFromApp,
});

export default connect(mapStateToProps)(DashBoardHeader);
