import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectPlatformSourceFromApp, selectTenant } from "store/app/selectors";
import LionwareKycProvider from "./LionwareKycProvider";

const KycProvider = ({ isRequiredBackButton, onBack, open, backSlideEffect = "", isInheritUserEnabled }) => {
    return (
        <LionwareKycProvider
            backSlideEffect={backSlideEffect}
            open={open}
            isInheritUserEnabled={isInheritUserEnabled}
            isRequiredBackButton={isRequiredBackButton}
            onBack={onBack}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    currentTenant: selectTenant,
    isApp: selectPlatformSourceFromApp,
});

export default connect(mapStateToProps)(KycProvider);
