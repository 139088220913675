import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useFormattedMessage, useSideModalScrollToTop, useUtils } from "hooks";
import { selectLocale, selectPlatformSourceFromApp } from "store/app/selectors";
import VideoChat from "assets/images/svg/video-chat.svg";
import EID from "assets/images/svg/eid.svg";
import PostOffice from "assets/images/svg/local-post-office.svg";
import Info from "assets/images/svg/info.svg";
import NewLoader from "components/NewLoader";
import { createLionware, getLionwareByTenant } from "api/FnxtApis";
import { sendFormError } from "i18n/messages/errors";
import PrevButton from "components/PrevButton";
import { fetchKycStatusSuccess } from "store/user/kyc/actions";

const messages = {
    sendFormError,
    providerHeading: "please_start_identification",
    freeText: "free",
    onlineText: "online",
    anytimeText: "anytime",
    videoChat: "video_chat",
    videoChatDescription: "video_chat_description",
    eid: "eid",
    eidDescription: "eid_description",
    localPostOffice: "local_post_office",
    localPostOfficeDescription: "local_post_office_description",
    kycProviderInfoText: "kyc_provider_info_text",
    kycProviderInfoTextPara1: "kyc_provider_info_text_para1",
    postidentButtonText: "identify_with_postident",
    kycContactSupportPara1: "kyc_contact_support_para1",
    kycContactSupportPara2: "kyc_contact_support_para2",
    here: "old_kyc_here",
};

const LionwareKycProvider = ({
    backSlideEffect = "",
    isApp,
    open,
    isInheritUserEnabled,
    isRequiredBackButton,
    onBack,
    fetchKycStatusSuccess,
    locale,
}) => {
    useSideModalScrollToTop();
    const currentTenant = useUtils().tenant;
    const { formatMessage } = useFormattedMessage();
    const [isLoadingLionware, setLoadingLionware] = useState(false);
    const [lionwareRedirectUrl, setLionwareRedirectUrl] = useState("");
    const [requestError, setRequestError] = useState(false);

    function getLionwareUrl() {
        setLoadingLionware(true);
        setRequestError(false);
        getLionwareByTenant(currentTenant?.TechnicalName)
            .then((response) => {
                if (response.status == 200 && response.data.Status === "created") {
                    setLionwareRedirectUrl(`${response?.data?.RedirectURL}`);
                    fetchKycStatusSuccess({ statusKyc: response.data.Status });
                    isApp
                        ? window?.ReactNativeWebView?.postMessage(
                              JSON.stringify({
                                  external_url: `${response?.data?.RedirectURL}&lang=${locale}`,
                              }),
                          )
                        : window.open(`${response?.data?.RedirectURL}&lang=${locale}`);
                } else if (response.status == 404 || response.data.Status === "failure" || response.data.Status === "expired") {
                    createLionware({ Tenant: currentTenant?.TechnicalName }).then((response) => {
                        if ((response.status == 201 || response.status == 200) && response?.data?.Status === "created") {
                            fetchKycStatusSuccess({ statusKyc: response.data.Status });
                            setLionwareRedirectUrl(`${response?.data?.RedirectURL}`);
                            isApp
                                ? window?.ReactNativeWebView?.postMessage(
                                      JSON.stringify({
                                          external_url: `${response?.data?.RedirectURL}&lang=${locale}`,
                                      }),
                                  )
                                : window.open(`${response?.data?.RedirectURL}&lang=${locale}`);
                        } else {
                            setRequestError(true);
                        }
                    });
                } else {
                    setRequestError(true);
                }
            })
            .finally(() => {
                setLoadingLionware(false);
            });
    }

    function onIdentifyWithPostident() {
        if (isInheritUserEnabled) return;
        setRequestError(false);
        if (lionwareRedirectUrl) {
            isApp
                ? window?.ReactNativeWebView?.postMessage(
                      JSON.stringify({
                          external_url: `${lionwareRedirectUrl}&lang=${locale}`,
                      }),
                  )
                : window.open(`${lionwareRedirectUrl}&lang=${locale}`);
        } else {
            getLionwareUrl();
        }
    }

    return (
        <>
            <div className={`kyc-provider lionwar-provider ${backSlideEffect}`}>
                <h3 className="mb-10 px-5">{formatMessage(messages.providerHeading)}</h3>
                <div className="border-top-1 border-bottom-1 pt-10 pb-8 px-10">
                    <div className="text-center mb-10">
                        <img src={"/images/postident.png"} alt="" />
                        <div className="points">
                            {formatMessage(messages.freeText)}
                            <span className="v-line">|</span>
                            {formatMessage(messages.onlineText)}
                            <span className="v-line">|</span>
                            {formatMessage(messages.anytimeText)}
                        </div>
                    </div>
                    <div className="d-flex mb-10">
                        <div className="icon">
                            <VideoChat />
                        </div>
                        <div className="content pl-4 pt-1">
                            <label className="text-16 fw-600">{formatMessage(messages.videoChat)}</label>
                            <p className="text-14">{formatMessage(messages.videoChatDescription)}</p>
                        </div>
                    </div>
                    <div className="d-flex mb-10">
                        <div className="icon">
                            <EID />
                        </div>
                        <div className="content pl-4 pt-1">
                            <label className="text-16 fw-600">{formatMessage(messages.eid)}</label>
                            <p className="text-14">{formatMessage(messages.eidDescription)}</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="icon">
                            <PostOffice />
                        </div>
                        <div className="content pl-4 pt-1">
                            <label className="text-16 fw-600">{formatMessage(messages.localPostOffice)}</label>
                            <p className="text-14">{formatMessage(messages.localPostOfficeDescription)}</p>
                        </div>
                    </div>
                </div>
                <div className="px-5 pt-10">
                    <div className="info-block d-flex align-start p-4 mb-6 border-radius bg-lighter-grey">
                        <Info className="mr-3" />
                        <p className="fw-400 mb-0 text-14">
                            {formatMessage(messages.kycProviderInfoText)}{" "}
                            <span className="fw-600">{formatMessage(messages.kycProviderInfoTextPara1)}</span>
                        </p>
                    </div>
                    {requestError && (
                        <span className="error pl-0 mb-4 d-block mw-100">{formatMessage(messages.sendFormError)}</span>
                    )}
                    <button
                        className={`mw-100 mb-0 ${isInheritUserEnabled || isLoadingLionware ? "disable-grey" : ""}`}
                        onClick={() => onIdentifyWithPostident()}
                    >
                        {isLoadingLionware ? <NewLoader /> : ""}

                        <i className="fas fa-external-link-alt mr-3" />
                        {formatMessage(messages.postidentButtonText)}
                    </button>
                </div>
            </div>
            {open && (
                <div className="bottom-bar-container hide-blank-bottom-bar">
                    {isRequiredBackButton && <PrevButton className="kp-back-button" onClick={onBack} />}
                </div>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    isApp: selectPlatformSourceFromApp,
    locale: selectLocale,
});

const mapDispatchToProps = (dispatch) => ({
    fetchKycStatusSuccess: (data) => dispatch(fetchKycStatusSuccess(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LionwareKycProvider);
