import * as Sentry from "@sentry/nextjs";

/**
 * Initialize Sentry and export it.
 *
 * Helper to avoid duplicating the init() call in every /pages/api file.
 * Also used in pages/_app for the client side, which automatically applies it for all frontend pages.
 *
 * Doesn't initialise Sentry if SENTRY_DSN isn't defined
 *
 * @see https://www.npmjs.com/package/@sentry/node
 */

/**
 * Configure Sentry tags for the current user.
 *
 * Allows to track all Sentry events related to a particular user.
 * The tracking remains anonymous, there are no personal information being tracked, only internal ids.
 *
 * @param userSession
 * @see https://www.npmjs.com/package/@sentry/node
 */
export const configureSentryUser = (userSession) => {
    if (process.env.SENTRY_DSN) {
        Sentry.configureScope((scope) => {
            scope.setUser({ email: userSession?.email });
        });
    }
};

/**
 * Configure Sentry tags for the currently used lang/locale.
 *
 * @param lang
 * @param locale
 * @see https://www.npmjs.com/package/@sentry/node
 */
export const configureSentryI18n = (lang, locale) => {
    if (process.env.SENTRY_DSN) {
        Sentry.configureScope((scope) => {
            // See https://www.npmjs.com/package/@sentry/node
            scope.setTag("lang", lang);
            scope.setTag("locale", locale);
        });
    }
};

/**
 * Configure the Sentry scope by extracting useful tags and context from the given request.
 *
 * @param req
 * @param tags
 * @param contexts
 * @see https://www.npmjs.com/package/@sentry/node
 */
export const configureRequest = (req) => {
    Sentry.configureScope((scope) => {
        scope.setTag("host", req?.headers?.host);
        scope.setTag("url", req?.url);
        scope.setTag("method", req?.method);
        scope.setExtra("query", req?.query);
        scope.setContext("headers", req?.headers);
    });
};
