import React, { useState } from "react";
import { useFormattedMessage, useSideModalScrollToTop } from "hooks";

import SideModal from "components/SideModal";
import { PropertyInterestSuccess } from "components/PropertyInterestModal";
import ContactMessageForm from "./ContactMessageForm";
import IntlFormattedMessage from "components/IntlFormattedMessage";
import { bookAppointmentURL, contactNumberLink, contactNumberText } from "constants/general_constants";
import NavLink from "components/NavLink";

const messages = {
    mainHeadingFirst: "any_question",
    mainHeadingSecond: "message_sent",
    telephoneHeading: "by_phone",
    telephoneContent: "contact_telephone_content",
    emailHeading: "contact_modal_email_heading",
    emailContent: "contact_modal_email_content",
    emailButtonText: "contact_modal_email_heading",
    appointmentHeading: "make_appointment",
    appointmentContent: "appointment_content",
    appointmentButtonText: "appointment_button_text",
    successText: "contact_success_text",
    yourDedicatedAdvisor: "your_dedicated_advisor",
    bookOnlineAppoitment: "book_online_appointment",
    haveQuestionsAboutInvestments: "have_questions_about_investment",
    bookNow: "book_now",
};

const ContactModal = ({ open, onCloseButtonClick, isBackArrowOnTop, onBackButtonClick }) => {
    useSideModalScrollToTop();

    const { formatMessage } = useFormattedMessage();

    const [backSlideEffect, setBackSlideEffect] = useState("");

    const [step, setStep] = useState(1);

    const contactTypes = (
        <div className={`property-interest-container ${backSlideEffect}`}>
            <div className="property-interest-block text-center menu-tab mb-4">
                <img className="action-image" src={"/images/interestTelephone.png"} alt="" />
                <h4 className="mt-0">{formatMessage(messages.telephoneHeading)}</h4>
                <p className="body-medium">
                    <IntlFormattedMessage id={messages.telephoneContent} />
                </p>
                <a href={`tel:${contactNumberLink}`}>
                    <button className="mb-0">
                        <i className="fas fa-phone rotate-90" /> {contactNumberText}
                    </button>
                </a>
            </div>
            <div className="property-interest-block text-center menu-tab mb-4">
                <img className="action-image" src={"/images/interestMail.png"} alt="" />
                <h4 className="mt-0">{formatMessage(messages.emailHeading)}</h4>

                <p>
                    <IntlFormattedMessage id={messages.emailContent} />
                </p>
                <button
                    className="mb-0"
                    onClick={() => {
                        setStep(2);
                        setBackSlideEffect("step-slide-left");
                    }}
                >
                    {formatMessage(messages.emailButtonText)}
                </button>
            </div>
            <div className="property-interest-block text-center menu-tab mb-4">
                <img className="action-image" src={"/images/appointment.png"} alt="" />
                <h4 className="mt-0">{formatMessage(messages.bookOnlineAppoitment)}</h4>

                <p>
                    <IntlFormattedMessage id={messages.haveQuestionsAboutInvestments} />
                </p>
                <NavLink isExternal href={bookAppointmentURL}>
                    <button className="mb-0">{formatMessage(messages.bookNow)}</button>
                </NavLink>
            </div>
        </div>
    );

    function renderContactStep(step) {
        switch (step) {
            case 1:
                return contactTypes;
            case 2:
                return (
                    <ContactMessageForm
                        backSlideEffect={backSlideEffect}
                        messageSent={() => {
                            setStep(3);
                            setBackSlideEffect("step-slide-left");
                        }}
                    />
                );
            case 3:
                return (
                    <PropertyInterestSuccess
                        backSlideEffect={backSlideEffect}
                        type="mail"
                        text={formatMessage(messages.successText)}
                    />
                );
            default:
                return contactTypes;
        }
    }

    return (
        <SideModal
            className="contact-modal"
            heading={step !== 3 ? formatMessage(messages.mainHeadingFirst) : formatMessage(messages.mainHeadingSecond)}
            open={open}
            isBackArrowOnTop={isBackArrowOnTop}
            onBackButtonClick={() => {
                if (step != 1) {
                    setStep(1);
                    setBackSlideEffect("step-slide-right");
                } else {
                    onBackButtonClick();
                }
            }}
            onCloseButtonClick={() => {
                onCloseButtonClick();
                setStep(1);
            }}
        >
            {renderContactStep(step)}
        </SideModal>
    );
};

export default ContactModal;
