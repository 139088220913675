import React from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getBaseApiURL } from "common/helper";
import { selectPlatformSourceFromApp } from "store/app/selectors";

const ActiveLink = ({
    isExternal,
    className,
    activeClassName,
    children,
    onClick,
    onKeyPress,
    forceActive = false,
    href,
    to,
    isApp,
    isForcePushed = false,
    ...props
}) => {
    // eslint-disable-next-line no-unused-vars
    const { dataAsset, dataStatus, ...restProps } = props;
    const router = useRouter();
    const isPDF = href?.includes?.("pdf");

    if ((isExternal && isExternal === true) || isForcePushed) {
        return isApp ? (
            <div
                onClick={() => {
                    isApp &&
                        window?.ReactNativeWebView?.postMessage(
                            isPDF
                                ? href
                                : JSON.stringify({
                                      external_url: href?.includes?.("http") ? href : getBaseApiURL() + href,
                                  }),
                        );
                }}
                className={`${className}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {children}
            </div>
        ) : (
            <a
                href={href || ""}
                onClick={(e) => {
                    isApp && isPDF && e.preventDefault();
                    onClick && onClick();
                }}
                className={`${className}`}
                target={isForcePushed && isExternal ? "_blank" : isForcePushed ? "_self" : "_blank"}
                rel="noopener noreferrer"
            >
                {children}
            </a>
        );
    }

    return isApp && isPDF ? (
        <div {...restProps}>
            <div
                onKeyPress={onKeyPress}
                onClick={() => {
                    isApp && isPDF && window?.ReactNativeWebView?.postMessage(href);
                }}
                className={`
                    ${className ? className : ""} 
                    ${
                        (href || to) === router.asPath || (href || to) === router.route
                            ? activeClassName
                            : forceActive
                              ? activeClassName
                              : ""
                    }
                `}
            >
                {children}
            </div>
        </div>
    ) : (
        <Link
            href={href || to || ""}
            {...restProps}
            prefetch={false}
            passHref
            onKeyPress={onKeyPress}
            onClick={(e) => {
                isApp && isPDF && e.preventDefault();
                onClick && onClick(e);
            }}
            className={`
                    ${className ? className : ""} 
                    ${
                        (href || to) === router.asPath || (href || to) === router.route
                            ? activeClassName
                            : forceActive
                              ? activeClassName
                              : ""
                    }
                `}
            data-asset={dataAsset}
            data-status={dataStatus}
        >
            {children}
        </Link>
    );
};

const mapStateToProps = createStructuredSelector({
    isApp: selectPlatformSourceFromApp,
});

export default connect(mapStateToProps)(ActiveLink);
